import {useScriptTag} from "@vueuse/core";
import {$toastMsg} from "@songfinch/shared/plugins/toast_msg";

const loadedScripts: Record<string, boolean> = {};

export default async function useLoadScript(url: string) {
    if (loadedScripts[url]) return;
    try {
        const {load} = useScriptTag(url, () => {}, {manual: true});
        await load();
        loadedScripts[url] = true;
    } catch (e) {
        $toastMsg("Failed to load: " + url);
    }
    return;
}
