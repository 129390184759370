import {isObject} from "./primitives";
import type {OptionKeyValueType, OptionType} from "@songfinch/types";


export const isOptionKeyValue = (option: OptionType): option is OptionKeyValueType => {
    return (
        isObject(option) && 
        typeof (option as OptionKeyValueType).label === "string" && 
        "value" in option
    );
};

export const isValidEmail = (email: string): boolean => {
    const emailRegex = /^[a-zA-Z\d._%+-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
};