import {debounce} from "lodash-es";
import app from "@songfinch/customer/app";
import store from "@songfinch/customer/store";

app.config.globalProperties.$env = window.appSettings.env;
app.config.globalProperties.$digitalTagTypes = ["digital", "streaming"];

store.commit("shared/setResolutionsFlags");
window.addEventListener("resize", debounce(() => {
    store.commit("shared/setResolutionsFlags");
}, 500));

app.config.globalProperties.$flushGTMScripts = () => {
    const pixels = document.body.querySelectorAll(':scope > [id^="sf_gtm_script"]');
    for (const p of pixels) document.body.removeChild(p);
};
