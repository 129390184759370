import {captureException} from "@sentry/vue";

type ErrorHandler = (e: unknown) => void | null

type Params = {
    errorHandler?: ErrorHandler ;
};

let defaultParams: Params = {
    errorHandler: (e) => {
        if (typeof e !== "object" || e === null) {
            e = new Error(e);
        }
        captureException(e);
    }
};

export function setDefaultParams(params: Params) {
    defaultParams = params;
}

export default async function catchError<T, E = unknown>(
    callback: Promise<T> | T,
    params?: Params
): Promise<[T, undefined] | [undefined, E]> {
    try {
        let res;
        if (callback instanceof Promise) {
            res = await callback;
        } else if (typeof callback === "function") {
            res = await callback();
        }
        return [res, undefined];
    } catch (error) {
        return handleError<E>(error, params);
    }
}

function handleError(error: unknown, params: Params) {
    params = {
        ...defaultParams,
        ...params
    };
    params.errorHandler(error);

    return [undefined, error];
}
