import axios from "axios";
import type {User, PostToExpressSignupResponseType} from "@songfinch/types/types";

export const fetchUser = async (user_id: number): Promise<User | undefined> => {
    try {
        const res = await axios.get("/user", {
            params: {
                user_id
            }
        });
        return res.data.user;
    } catch (error) {
        return undefined;
    }
};

export const postToExpressSignup = async (email: string, name?: string): Promise<PostToExpressSignupResponseType> => {
    try {
        await axios.post("/user/express_signup", {
            email,
            name
        });
        return {success: true};
    } catch (error) {
        if (axios.isAxiosError(error)) {
            if (error.response?.data.error === "Name is required") {
                return {success: false, nameRequired: true};
            }
        }
        throw error;
    }
};