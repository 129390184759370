import useInstantProductBuilder, {
    loadInstantCollection,
    setLastPageVisited,
    setVitamixCollection,
    setSelectedInstantProduct
} from "@songfinch/customer/composables/useInstantProductBuilder";
import {setMetaToAllChildren, skipRouteToNext} from "@songfinch/customer/helpers/routes_helper";
import store from "@songfinch/customer/store";
import router from "@songfinch/customer/router";
import {setLastReferralPage} from "@songfinch/customer/composables/useReferralPage";

const InstantProductBuilder = () => import("@songfinch/customer/views/instant/builder/InstantProductBuilder.vue");
const IpBuilderSteps = () => import("@songfinch/customer/views/instant/builder/subpages/IpBuilderSteps.vue");
const IpBuilderStep = () => import("@songfinch/customer/views/instant/builder/subpages/IpBuilderStep.vue");
const IpLoadingPreview = () => import("@songfinch/customer/views/instant/builder/subpages/IpLoadingPreview.vue");
const IpRecipientNames = () => import("@songfinch/customer/views/instant/builder/subpages/IpRecipientNames.vue");
const IpPreview = () => import("@songfinch/customer/views/instant/builder/subpages/IpPreview.vue");
const IpConfirm = () => import("@songfinch/customer/views/instant/builder/subpages/IpConfirm.vue");
const IpAddons = () => import("@songfinch/customer/views/instant/builder/subpages/IpAddons.vue");
const IpCustomerGreeting = () => import("@songfinch/customer/views/instant/builder/subpages/IpCustomerGreeting.vue");
const IpCollections = () => import("@songfinch/customer/views/instant/builder/subpages/IpCollections.vue");

function collectionGuard(to) {
    const {vitamixCollectionId} = useInstantProductBuilder();
    if (!vitamixCollectionId.value) return {name: "IpCollections", query: to.query};
}

function stepsGuard(to, from) {
    const {collectionData} = useInstantProductBuilder();
    if (!to.params.step) {
        const step = from.meta.index > to.meta.index ? -1 : 1;
        return {name: "IpBuilderStep", params: {step}, query: to.query};
    }
    if (to.params?.step === "-1") {
        return {name: "IpBuilderStep", params: {step: collectionData.value.questions.length}, query: to.query};
    }
    if (!collectionData.value.questions[to.params.step - 1]) return {name: "IpBuilderStep", params: {step: 1}, query: to.query};
}

async function namesGuard (to, from) {
    const {collectionData} = useInstantProductBuilder();
    return skipRouteToNext(to, from, !collectionData.value.choices["name"]);
}

async function addonsGuard (to, from) {
    const {collectionData} = useInstantProductBuilder();
    const skipAddons = collectionData.value?.skip_addon_page ?? false;
    return skipRouteToNext(to, from, skipAddons);
}

async function previewGuard () {
    const {previewsList} = useInstantProductBuilder();
    if (!previewsList.value.length) return {name: "IpBuilderStep", params: {step: 1}};
}

function productInCartGuard () {
    const {selectedInstantProduct} = useInstantProductBuilder();
    if (!store.state.cart.cart.findProductByName(selectedInstantProduct.value)) return {name: "IpPreview"};
}

async function customerGreetingGuard(to, from) {
    try {
        const {collectionData} = useInstantProductBuilder();
        const {loadSingleProduct} = await import("@songfinch/customer/composables/useProduct");
        const product = await loadSingleProduct({productSlug: "customer-greeting-for-instant-product"});
        to.meta.product = product;
        return skipRouteToNext(to, from, !collectionData.value.pam_enabled || !product);
    } catch (error) {
        return skipRouteToNext(to, from, true);
    }
}

const routes = [
    {
        path: "/instant-builder",
        name: "InstantProductBuilder",
        component: InstantProductBuilder,
        meta: {
            animation: "pageSlide",
            hideTopBar: true,
            hideNavgiation: true,
            hideFooter: true,
            hideStartButton: true,
            defaultReferralPage: "InstantProductBundle"
        },
        redirect: {name: "IpCollections"},
        async beforeEnter(to, from) {
            setLastReferralPage(to, from);

            const {vitamixCollectionId} = useInstantProductBuilder();
            const queryCollectionId = +to.query.collection_id;
            if (queryCollectionId) {
                const isDifferentCollection = queryCollectionId !== vitamixCollectionId.value;
                await setVitamixCollection(to.query.collection_id);
                // set Vitamix Collection will set selectedInstantProduct but if query exist we overwriting it
                if (to.query.instant_product) setSelectedInstantProduct(to.query.instant_product);
                if (isDifferentCollection) return true;
            } else if (vitamixCollectionId.value) {
                await loadInstantCollection();
            }

            const {lastStepVisited} = useInstantProductBuilder();
            if (lastStepVisited.value) {
                try {
                    const lastPageRoute = router.resolve({path: lastStepVisited.value});
                    lastPageRoute.query = {...lastPageRoute.query, ...to.query};
                    if (to.path !== lastPageRoute.path) return lastPageRoute;
                } catch (e) {
                    return console.error(e);
                }
            }
        },
        children: [
            {
                path: "collections",
                name: "IpCollections",
                component: IpCollections,
            },
            {
                path: "step",
                component: IpBuilderSteps,
                meta: {skipLastPage: true},
                beforeEnter: [collectionGuard, stepsGuard],
                children: [
                    {
                        path: ":step?",
                        name: "IpBuilderStep",
                        component: IpBuilderStep
                    }
                ]
            },
            {
                path: "recipient",
                name: "IpRecipientNames",
                component: IpRecipientNames,
                beforeEnter: [collectionGuard, namesGuard]
            },
            {
                path: "loading",
                name: "IpLoadingPreview",
                component: IpLoadingPreview,
            },
            {
                path: "preview",
                name: "IpPreview",
                component: IpPreview,
                beforeEnter: [collectionGuard, previewGuard]
            },
            {
                path: "confirm",
                name: "IpConfirm",
                component: IpConfirm,
                beforeEnter: [collectionGuard, previewGuard, productInCartGuard]
            },
            {
                path: "customer-greeting",
                name: "IpCustomerGreeting",
                component: IpCustomerGreeting,
                beforeEnter: [collectionGuard, customerGreetingGuard, addonsGuard, previewGuard, productInCartGuard]
            },
            {
                path: "addons",
                name: "IpAddons",
                component: IpAddons,
                meta: {
                    nextPage: "Cart"
                },
                beforeEnter: [collectionGuard, addonsGuard, previewGuard, productInCartGuard]
            }
        ],
    }
];

setMetaToAllChildren(
    routes.find(r => r.name === "InstantProductBuilder"),
    {CMSData: "instantBuilder"},
    to => {
        if (to.meta.skipLastPage) return;
        setLastPageVisited(to.fullPath);
    }
);

export default routes;
