import app from "@songfinch/customer/app";
import {init, setTag, browserTracingIntegration} from "@sentry/vue";
import store from "@songfinch/customer/store";
import Cookies from "js-cookie";
import {merge, mapValues} from "lodash-es";
import useInstantProductBuilder from "@songfinch/customer/composables/useInstantProductBuilder";
import {toValue} from "vue";
import tryReload from "@songfinch/customer/helpers/tryReload";

import {sentryBaseConfig} from "@songfinch/config/sentryConfig";

import {isAxiosError, isSongfinchError} from "@songfinch/utils/src/validators";

import router from "../router";

import type {AxiosError} from "axios";
import type {SongFinchError} from "@songfinch/types";
import {useSongBuilderStore} from "@songfinch/customer/store_v2/useSongBuilderStore";

if (window.appSettings.sentry_dns) {
    const js_hash = window.appSettings.version;
    init({
        ...sentryBaseConfig,
        app,
        integrations: [
            ...sentryBaseConfig.integrations,
            browserTracingIntegration({router}),
        ],
        beforeSend(event, hint) {
            if (!window.appSettings.sentry_dns) { //We can clear this value if we want to stop error tracking
                return null;
            }

            const error = hint.originalException as SongFinchError | AxiosError;
            if (!event.extra?.fromReload) {
                if (tryReload(error, "sentry")) return null;
            }

            //TODO: add better error handling
            if (isSongfinchError(error) && error._sfErrorIgnore) {
                return null;
            }


            //GTM
            if (error?.stack) {
                const gtm = error?.stack?.indexOf("googletagmanager.com/gtm.js?id=") > -1;
                const dataLayer = window.dataLayer || [];

                if (gtm) {
                    event.tags ||= {};
                    event.tags.logger = "gtm";
                    event.extra ||= {};
                    event.extra.dataLayer = dataLayer.map((i) => {
                        try {
                            return JSON.stringify(i, null, " ");
                        } catch (error) {
                            return "[cannot be serialized]: " + (error as {message: string})?.message;
                        }
                    });
                }
            }

            if (isAxiosError(error)) {
                event.extra ||= {};
                event.extra.errorInfo = {
                    message: error?.message,
                    url: error?.request?.responseURL,
                    method: error?.config?.method,
                    status: error?.response?.status,
                    requestPayload: error?.config?.data, // Request payload
                    responsePayload: error?.response?.data, // Response error payload
                };
            }

            // UserID Resolution
            // * User (external user id)
            // * Cookie (segment user id)
            // * Cookie (segment anonymous user id)
            // * Cookie (CIO anonymous user id)
            const id = store.state.auth.user?.external_user_id ||
                Cookies.get("ajs_user_id") ||
                Cookies.get("ajs_anonymous_id") ||
                Cookies.get("_cioanonid");

            const storeSongBuilder = useSongBuilderStore();

            const extra = {
                cart: store.state.cart,
                song_builder: storeSongBuilder.$state,
                instant_builder: mapValues(useInstantProductBuilder(), v => (v ? toValue(v.value) : v)),
                js_hash,
                redirectsList: undefined as string[] | undefined,
            };

            if (isSongfinchError(error) && error?.redirectsList?.length) {
                extra.redirectsList = error?.redirectsList;
            }

            merge(event, {extra});
            event.user = {id};

            if (event.exception) {
                const frames = event.exception.values?.[0]?.stacktrace?.frames;
                const isOwnError = !frames || frames.some(frame => frame.filename && frame.filename.includes(window.location.hostname));
                event.tags ||= {};
                if (isOwnError) event.tags.sf_error_source = "internal_error";
                else event.tags.sf_error_source = "external_error";
            }

            return event;
        }
    });
    setTag("sf_pack", "customer");
    setTag("sf_origin", "frontend");
    setTag("js_hash", js_hash);
}
