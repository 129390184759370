import store from "../store";
const EmailMePassword = () => import("@songfinch/customer/components/auth/EmailMePassword");
const StudioFaqPopup = () => import("@songfinch/customer/components/song_builder/StudioFaqPopup");
const OTPSinginPopup = () => import("@songfinch/customer/components/auth/otp/OTPSinginPopup.vue");
const SignupPopup = () => import("@songfinch/customer/components/auth/SignupPopup");
const EventReminderPopup = () => import("@songfinch/customer/components/event_reminder/EventReminderPopup");

async function authPopupHelper(direction, component, swalSettings) {
    if (store.state.auth.user) {
        store.commit("auth/redirectToUserPage");
        return false;
    } else {
        const loadedComponent = (await component()).default;
        setTimeout(() =>
            store.commit("shared/showModal", {
                component: loadedComponent,
                swalSettings: swalSettings,
                responsive: {
                    767: {
                        slide: "bottom"
                    },
                }
            })
        );
        return direction.from.name ? false : {name: "Home"};
    }
}

export default [
    {
        path: "/set-reminder",
        alias: "/occasion-reminder",
        name: "SetRemider",
        beforeEnter: async (to, from) => {
            const component = (await EventReminderPopup()).default;
            setTimeout(() =>
                store.commit("shared/showModal", {
                    component,
                    swalSettings: {width: 770},
                })
            );
            return from.name ? false : {name: "Home"};
        },
    },
    {
        path: "/songwriting-101",
        name: "Songwriting101",
        beforeEnter: async (to, from) => {
            const component = (await StudioFaqPopup()).default;
            setTimeout(() =>
                store.commit("shared/showModal", {
                    component,
                    props: {activeFAQ: to.query.question_id},
                    swalSettings: {width: 850},
                })
            );
            return from.name ? false : {name: "Home"};
        },
    },
    {
        path: "/reset-password",
        name: "EmailMePassword",
        beforeEnter: (to, from) => {
            return authPopupHelper({to, from}, EmailMePassword);
        },
    },
    {
        path: "/login",
        name: "Login",
        alias: "/users/sign_in",
        beforeEnter: (to, from) => {
            store.commit("auth/setRedirectQuery", to.query);
            return authPopupHelper({to, from}, OTPSinginPopup);
        },
    },
    {
        path: "/signup",
        name: "Signup",
        alias: "/users/sign_up",
        beforeEnter: (to, from) => {
            store.commit("auth/setRedirectQuery", to.query);
            return authPopupHelper({to, from}, SignupPopup, {width: 770});
        },
    }
];
