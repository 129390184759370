<template>
    <div class="d-tag" :class="`${tagColor}`">
        <DText type="span" variant="l-1">
            {{label}}
        </DText>
    </div>
</template>

<script setup lang="ts">
    import {computed} from "vue";
    import DText from "../atoms/DText.vue";
    import type {TagColorType} from "@songfinch/types";

    type DTagPropsType = {
        label: "new" | "premium" | "your artist" | "only on songfinch" | (NonNullable<unknown> & string);
        tagColor?: TagColorType,
    };

    const props = defineProps<DTagPropsType>();

    const tagColor = computed(() => {
        if (props.tagColor) return props.tagColor;
        if (props.label === "new") return "white";
        if (props.label === "best seller") return "white-60";
        if (props.label === "only on songfinch") return "white-60";
        if (props.label === "premium") return "orange";
        if (props.label === "your artist") return "blue";
        return "blue";
    });
</script>


<style scoped lang="scss">
.d-tag {
  border-radius: var(--radius-4);
  display: inline-block;
  white-space: nowrap;
  text-transform: uppercase;
  line-height: 1;
  letter-spacing: .25px;
  padding: 2.5px 7px;

  &.orange {
    background-color: var(--tangerine);
    color: var(--deep-black);
  }

  &.white {
    background-color: var(--white);
    color: var(--deep-black);
  }

  &.white-60 {
    background-color: var(--white-tint-60);
    color: var(--deep-black);
  }

  &.blue {
    background-color: var(--vivid-blue);
    color: var(--white);
  }

  &.black-20 {
    background-color: var(--soft-black-tint-20);
    color: var(--white);
  }
  
  &.muteYellow {
    background-color: #FFD699;
    color: var(--white);
  }
}
</style>
