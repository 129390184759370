import {setMetaToAllChildren} from "../helpers/routes_helper";
import router from "./index";
import store from "../store";
import {goToReferalPage, setLastReferralPage} from "@songfinch/customer/composables/useReferralPage";

const SongBuilder = () => import("@songfinch/customer/views/build_song/SongBuilder");
const BsOccasionRecipient = () => import("@songfinch/customer/views/build_song/chapters/pre_purchase/BsOccasionRecipient");
const BsGifterEmail2 = () => import("@songfinch/customer/views/build_song/chapters/pre_purchase/BsGifterEmail2");
const BsSongArtist = () => import("@songfinch/customer/views/build_song/chapters/pre_purchase/BsSongArtist");
const BsSongOptions = () => import("@songfinch/customer/views/build_song/chapters/pre_purchase/BsSongOptions");
const BsSongArtistSelection = () => import("@songfinch/customer/views/build_song/chapters/pre_purchase/BsSongArtistSelection");
const BsStoryDetailsCombined = () => import("@songfinch/customer/views/build_song/chapters/pre_purchase/BsStoryDetailsCombined");
const BsArtistsRecommendation = () => import("@songfinch/customer/views/build_song/chapters/pre_purchase/BsArtistsRecommendation");
const BsArtistConfirmation = () => import("@songfinch/customer/views/build_song/chapters/pre_purchase/BsArtistConfirmation");
const BsReview = () => import("@songfinch/customer/views/build_song/chapters/pre_purchase/BsReview");
const BsPreCart = () => import("@songfinch/customer/views/build_song/chapters/pre_purchase/BsPreCart");
const BsChangeArtist = () => import("@songfinch/customer/views/build_song/chapters/pre_purchase/BsChangeArtist.vue");
import {useSongBuilderStore, songBuilderSlug} from "@songfinch/customer/store_v2/useSongBuilderStore";

async function songbuilderGuard(to) {
    if (to.query.slug) songBuilderSlug.value = to.query.slug;
    const storeSongBuilder = useSongBuilderStore();
    await storeSongBuilder.init();
}

async function skipSongBuilderRoute(to, from, next, skip) {
    if (skip) {
        const storeSongBuilder = useSongBuilderStore();
        const isPrevPage = (from.meta.index === undefined && to.meta.index > 0) || from.meta.index > to.meta.index;
        if (isPrevPage && !to.meta.prevPage) {
            goToReferalPage();
            return next(false);
        }
        const nextPage = {name: isPrevPage ? to.meta.prevPage : to.meta.nextPage, params: to.params, query: to.query};
        storeSongBuilder.setLastSongBuildPage(nextPage);
        await storeSongBuilder.saveData();
        return next(nextPage);
    }
    next();
}

function bsPageRedirectionGuard(to, from, next) {
    const storeSongBuilder = useSongBuilderStore();
    const isLastBuilderPage = from.matched[0]?.name === "SongBuilder";
    const lastPage = storeSongBuilder.lastSongBuildPage;
    if (!isLastBuilderPage && lastPage?.name && lastPage.name !== to.name) {
        try {
            lastPage.query = {...lastPage.query, ...to.query};
            router.resolve(lastPage);
            return next(lastPage);
        } catch (e) {
            storeSongBuilder.setLastSongBuildPage(undefined);
            return next({name: "SongBuilder"});
        }
    }
    return next();
}

function checkForOriginalSongBundler(to) {
    if (to.query.original_song_product) {
        const storeSongBuilder = useSongBuilderStore();
        storeSongBuilder.song_product_name = to.query.original_song_product;
    }
}

function checkOriginalInCartGuard() {
    return store.state.cart.cart.hasOriginalSong ? true : {name: "BsReview"};
}

async function gifterEmailGuard(to, from, next) {
    const storeSongBuilder = useSongBuilderStore();
    if (store.state.auth.user || storeSongBuilder.gifter_email) {
        if (store.state.auth.user?.email) {
            storeSongBuilder.gifter_email = store.state.auth.user.email;
        }
        return await skipSongBuilderRoute(to, from, next, true);
    }
    return next();
}

const songBuilderRoutes = {
    path: "/song-builder",
    alias: "/build-your-song",
    name: "SongBuilder",
    component: SongBuilder,
    redirect: to => ({name: to.matched[0].children[0].name}), // Redirect always to first child
    meta: {
        CMSData: "songBuilderPrePurchase",
        animation: "pageSlide",
        hideTopBar: true,
        hideNavgiation: true,
        hideFooter: true,
        hideStartButton: true,
        defaultReferralPage: "Home"
    },
    beforeEnter: [songbuilderGuard, setLastReferralPage, checkForOriginalSongBundler],
    children: [
        {
            path: "recipient",
            name: "BsOccasionRecipient",
            component: BsOccasionRecipient,
            beforeEnter: [bsPageRedirectionGuard]
        },
        {
            path: "gifter-email",
            name: "BsGifterEmail2",
            component: BsGifterEmail2,
            meta: {hideSongbuilderHeader: true},
            beforeEnter: [gifterEmailGuard]
        },
        {
            path: "artist",
            name: "BsSongArtist",
            component: BsSongArtist,
            async beforeEnter(to, from, next) {
                const storeSongBuilder = useSongBuilderStore();
                const skip = storeSongBuilder.selected_artists?.find(a => a.fromSinglePage);
                await skipSongBuilderRoute(to, from, next, skip);
            }
        },
        {
            path: "artist-selection",
            name: "BsSongArtistSelection",
            component: BsSongArtistSelection,
            async beforeEnter(to, from, next) {
                const storeSongBuilder = useSongBuilderStore();
                await skipSongBuilderRoute(to, from, next, !storeSongBuilder.preferred_artist_switch);
            }
        },
        {
            path: "song-options",
            name: "BsSongCharacteristics",
            component: BsSongOptions,
            async beforeEnter(to, from, next) {
                const storeSongBuilder = useSongBuilderStore();
                const userSelectedAnArtistSwitch = storeSongBuilder.preferred_artist_switch === undefined;
                await skipSongBuilderRoute(to, from, next, userSelectedAnArtistSwitch);
            }
        },
        {
            path: "recommended-artists",
            name: "BsSongArtistsList",
            component: BsArtistsRecommendation,
            async beforeEnter(to, from, next) {
                const storeSongBuilder = useSongBuilderStore();
                if (!storeSongBuilder.genre || !storeSongBuilder.gender) {
                    return await skipSongBuilderRoute(to, from, next, true);
                }
                await skipSongBuilderRoute(to, from, next, storeSongBuilder.preferred_artist_switch);
            }
        },
        {
            path: "artist-confirmation",
            name: "BsArtistConfirmation",
            component: BsArtistConfirmation,
            async beforeEnter(to, from, next) {
                const storeSongBuilder = useSongBuilderStore();
                if (!(storeSongBuilder.selected_artists?.length || storeSongBuilder.selected_recommended_artist)) {
                    return await skipSongBuilderRoute(to, from, next, true);
                }
                return next();
            }
        },
        {
            path: "details",
            name: "BsStoryDetailsCombined",
            component: BsStoryDetailsCombined
        },
        {
            path: "review",
            name: "BsReview",
            component: BsReview,
            meta: {hideProgressNav: true},
            beforeEnter: async () => {
                const storeSongBuilder = useSongBuilderStore();
                return await storeSongBuilder.validateSongData({toPage: "BsReview"});
            }
        },
        {
            path: "pre-cart",
            name: "BsPreCart",
            component: BsPreCart,
            meta: {hideProgressNav: true, customPageViewedAnalyticsTrigger: true, nextPage: "Cart"},
            beforeEnter: [checkOriginalInCartGuard]
        },
        {
            path: "change-artist",
            name: "BsChangeArtist",
            component: BsChangeArtist,
            meta: {hideProgressNav: true},
            beforeEnter: [checkOriginalInCartGuard]
        },
        {
            path: "artist-confirmation-changed",
            name: "BsArtistConfirmationChanged",
            component: BsArtistConfirmation,
            meta: {hideProgressNav: true, nextPage: "BsPreCart"},
            beforeEnter: [checkOriginalInCartGuard]
        }
    ]
};


setMetaToAllChildren(
    songBuilderRoutes,
    {CMSData: "songBuilderPrePurchase"}
);

export default [songBuilderRoutes];
