import {ref, computed} from "vue";
import {useStore} from "vuex";

import {StorySong} from "@songfinch/types/types";
import {isValidEmail} from "@songfinch/utils/src/validators";

type AuthModalTypes = "default" | "story"

const authExpressModalOpen = ref(false);
const authModalSecondaryText = ref("Access your Songfinch account");

import {submitAccessPasscode, fetchAccessPasscode} from "@songfinch/data-source/auth";
import {postToExpressSignup} from "@songfinch/data-source/users";
import {useNotification} from "@songfinch/design-system/src/composables/useNotification";
import {normalizeError} from "@songfinch/utils/src/error";

import type {TrackEventHandler} from "@songfinch/types";

export default function useAuthExpress(trackEvent?: TrackEventHandler) {
    const store = useStore();
    const {setNotification} = useNotification();
    const userName = ref("");
    const userEmail = ref("");
    const otpCode = ref("");
    const authShowNameField = ref(false);
    const useOtpCodeFlow = ref(false);

    const authFormIsValid = computed(() => {
        const isEmailValid = isValidEmail(userEmail.value);

        if (authShowNameField.value === false) {
            return isEmailValid;
        }
        return isEmailValid && userName.value.length > 0;
    });

    const otpCodeIsValid = computed(() => {
        return !!(otpCode.value && otpCode.value.length === 6);
    });

    const setAuthModalSecondaryText = (type: AuthModalTypes, data?: StorySong) => {
        if (type === "story" && data) {
            const songTitle = data.title;
            authModalSecondaryText.value = `For privacy, participation on ${songTitle} is only allowed if you’re a verified user. Please verify your email and join the conversation!`;
        } else {
            authModalSecondaryText.value = "Access your Songfinch account";
        }
    };

    const openExpressAuthModal = (type: AuthModalTypes = "default", data?: StorySong) => {
        cleanAuthExpressModal();
        setAuthModalSecondaryText(type, data);
        authExpressModalOpen.value = true;
    };

    const closeAuthExpressModal = () => {
        cleanAuthExpressModal();
        authExpressModalOpen.value = false;
    };

    const cleanAuthExpressModal = () => {
        userName.value = "";
        userEmail.value = "";  
        useOtpCodeFlow.value = false;
        authShowNameField.value = false;
    };

    const handleOTPInitiatition = async () => {
        if (!authFormIsValid.value) {
            // todo throw error maybe?
            return;
        }
        try {
            const res = await postToExpressSignup(userEmail.value, userName.value);
            if (res.success) {
                useOtpCodeFlow.value = true;
                if (authShowNameField.value) {
                    handleTrackEvent("Express Auth Modal New User Signup Submitted");
                } else {
                    handleTrackEvent("Express Auth Modal Sign-in Pressed");
                }
                handleTrackEvent("Express Auth OTP Form Shown");
                return;
            }
            userName.value = "";
            authShowNameField.value = true;
            handleTrackEvent("Express Auth Modal Sign-in Pressed");
            handleTrackEvent("Express Auth Modal New User Signup Shown");
        } catch (error: unknown) {
            setNotification({
                type: "error",
                text: normalizeError(error)
            });
        }
    };

    const handleOTPAuthentication = async () => {
        handleTrackEvent("Express Auth OTP Submitted");

        if (!otpCodeIsValid.value) {
            setNotification({
                type: "error",
                text: "Please enter the 5-digit code sent to your email."
            });
        }
        try {
            const res = await submitAccessPasscode(otpCode.value, "story_page");
            if (res.user) {
                cleanAuthExpressModal();
                authExpressModalOpen.value = false;
                store.dispatch("auth/afterLogin", {user: res.user});
                handleTrackEvent("Express Auth OTP Success");
            }
        } catch (error) {
            const normalizedError = normalizeError(error);

            if (normalizedError === "Passcode expired") {
                handleTrackEvent("Express Auth OTP Expired");
            }

            setNotification({
                type: "error",
                heading: "Error",
                text: normalizedError,
            });
        }
    };

    const resendOTPCode = async () => {
        try {
            await fetchAccessPasscode({
                email: userEmail.value,
                method: "email",
                sign_in_origin: "story_page",
                resent: true
            });
            setNotification({
                type: "success",
                text: "New code sent!"
            });
            handleTrackEvent("Express Auth OTP Code Resend Pressed");
        } catch (error) {
            setNotification({
                type: "error",
                heading: "Error",
                text: normalizeError(error)
            });
        }
    };

    const handleTrackEvent = (eventName: string) => {
        if (trackEvent) {
            trackEvent(eventName);
        }
    };


    return {
        userName,
        userEmail,
        otpCode,
        authExpressModalOpen,
        authShowNameField,
        authFormIsValid,
        authModalSecondaryText,
        useOtpCodeFlow,
        otpCodeIsValid,
        openExpressAuthModal,
        closeAuthExpressModal,
        handleOTPInitiatition,
        handleOTPAuthentication,
        resendOTPCode
    };
}
