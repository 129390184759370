import axios from "axios";
import {SongBuilderPayloadType} from "@songfinch/types";

export async function createSongBuilder(payload: SongBuilderPayloadType): SongBuilderResponseType {
    const res = await axios.post("songbuilders", payload);
    return res.data;
}

export async function fetchSongBuilder(slug: string): SongBuilderResponseType {
    const res = await axios.get("songbuilders/" + slug);
    return res.data;
}

export async function fetchSongBuilderByEmail(email: string): SongBuilderResponseType {
    const res = await axios.get("songbuilders/by_email/", {params: {email}});
    return res.data;
}

export async function fetchSongBuilderByExternalId(external_user_id: string): SongBuilderResponseType {
    const res = await axios.get("songbuilders/by_external_user_id", {params: {external_user_id}});
    return res.data;
}

export async function updateSongBuilder(slug, payload: SongBuilderPayloadType): SongBuilderResponseType {
    const res = await axios.patch("songbuilders/" + slug, payload);
    return res.data;
}
