import router from "@songfinch/customer/router";
import onPageLoaded from "@songfinch/customer/composables/onPageLoaded";

declare global {
    interface Window { __attentive: unknown; }
}

const setAttentiveScript = () => {
    const script = document.createElement("script");
    script.src = "https://cdn.attn.tv/songfinch/dtag.js";
    script.id = "attentive";
    script.defer = true;
    document.head.appendChild(script);
};

onPageLoaded(async () => {
    if (window.Cypress) return;
    if (router.currentRoute.value.matched[0]?.name !== "Story") {
        setAttentiveScript();
    } else {
        const useStoryImport = await import("@songfinch/customer/composables/story/useStory");
        const {story} = useStoryImport.default();
        if (story.value?.show_attentive_modal) {
            setAttentiveScript();
        }
    }
});
