import {$toastMsg} from "@songfinch/shared/plugins/toast_msg";
import {useStorage} from "@vueuse/core";
import {captureException} from "@sentry/vue";

type SfReloadStorageData = {
    timestamp: number,
    count: number
}
let reloadCalled = false;

export default function tryReload(error: unknown, from: string, path?: string): boolean {
    if (reloadCalled) return true;
    if (!error?.message?.match(/(Failed to fetch dynamically imported module|Importing a module script failed)/)?.length) return false;
    const storage = useStorage<SfReloadStorageData>("sf_reload", {count: 0, timestamp: 0}, null, {writeDefaults: false});
    const fiveMinutes = 5 * 60 * 1000;
    const timestampDiff = Date.now() - storage.value.timestamp;
    if (timestampDiff > fiveMinutes) storage.value.count = 0;
    if (storage.value.count <= 3 ) {
        reloadCalled = true;
        storage.value.count++;
        storage.value.timestamp = Date.now();
        window.appSettings.sentry_dns = null; // Clearing to stop any sentry errors
        path ? (location.href = path) : window.location.reload();
        return true;
    }
    error._sfErrorIgnore = false;
    captureException(error, {
        extra: {fromReload: from}
    });
    $toastMsg("Unable to refresh the page automatically. Please refresh the page to apply the latest changes.");
    return false;
}
