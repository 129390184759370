const ArtistSingle = () => import("@songfinch/customer/views/artists/ArtistSingle");
const Artists = () => import("@songfinch/customer/views/artists/Artists");
const OpportunityConfirmation = () => import("@songfinch/customer/views/artists/OpportunityConfirmation");


export default [
    {
        path: "/artists",
        name: "ArtistsList",
        component: Artists,
    },
    {
        path: "/artists/:id",
        name: "ArtistSingle",
        meta: {CMSData: "artistSingle", customPageViewedAnalyticsTrigger: true},
        component: ArtistSingle
    },
    {
        path: "/opportunity-confirmation",
        name: "OpportunityConfirmation",
        component: OpportunityConfirmation,
    }
];
