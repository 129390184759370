import {createStore} from "vuex";

import shared from "./shared";
import cms from "./cms";
import core_cms from "@songfinch/shared/store/cms_core";
import cart from "./cart";
import auth from "./auth";
import artist from "./artist";
import store from "./store";
import playlist from "./playlist";

export default createStore({
    modules: {
        shared,
        core_cms,
        cms,
        cart,
        auth,
        artist,
        store,
        playlist
    }
});
