import axios, {type AxiosRequestConfig} from "axios";
import type {
    SignInOrigin, 
    CheckForPasswordResponse, 
    FetchAccessPasscodeOptions, 
    AccessPasscodeResponse,
    SubmitAccessPasscodeResponse,
    FetchCheckUserForOTPResponseType
} from "@songfinch/types";


export async function fetchCheckUserForOTP(email: string): Promise<FetchCheckUserForOTPResponseType>  {
    const res = await axios.post<CheckForPasswordResponse>("/access_passcodes/check_user_password/", {email});
    return res.data;
}

export async function fetchAccessPasscode(payload: FetchAccessPasscodeOptions): Promise<AccessPasscodeResponse> {
    const res = await axios.post<AccessPasscodeResponse>("/access_passcodes/", payload);
    return res.data;

}

export async function submitAccessPasscode(passcode: string, sign_in_origin?: SignInOrigin): Promise<SubmitAccessPasscodeResponse> {
    const res = await axios.post<SubmitAccessPasscodeResponse>("/access_passcodes/create_session", {passcode, sign_in_origin}, {meta: {updateCSRFToken: true}} as AxiosRequestConfig);
    return res.data;
}
