import axios from "axios";
import {logError} from "@songfinch/utils/src/error";

const CancelToken = axios.CancelToken;
let source;

export default {
    namespaced: true,
    state: {
        allCMSValues: null,
        pageData: null,
        navigation: null,
        footer: null,
        pagePrevGroup: null,
    },
    mutations: {
        initNavs(state) {
            const navs = window.appSettings.navs ?? [];
            Object.keys(navs).map(k => {
                const arrKeys = k.split(".");
                if (["footer", "navigation"].includes(arrKeys[0])) {
                    state[arrKeys[0]] ??= {};
                    state[arrKeys[0]][arrKeys[1]] = navs[k];
                }
            });
        },
        setCMSData (state, {data, group}) {
            data = Object.keys(data).reduce((val, k) => {
                const arrKeys = k.split(".");
                if (group === arrKeys[0]) {
                    state.pageData ??= {};
                    state.pageData[arrKeys[1]] = data[k];
                    switch (arrKeys[1]) {
                    case "seo_title":
                        document.title = data[k];
                        break;
                    }
                } else {
                    val[arrKeys[0]] = {};
                    val[arrKeys[0]][arrKeys[1]] = data[k];
                }
                return val;
            }, {});
            state.allCMSValues = data;
        }
    },
    actions: {
        async loadData({state, commit}, payload) {
            const currentPath = location.pathname;
            const group = payload?.group ? payload.group : currentPath;
            if (source) {
                source.cancel();
            }
            source = CancelToken.source();

            //Don't reset data for the same pages so we don't have blink on children or custom pages

            if (state.pagePrevGroup && state.pagePrevGroup !== group) {
                state.pageData = null;
            }
            state.pagePrevGroup = group;

            let data;
            try {
                data = await this.dispatch("core_cms/loadCMSData", {
                    queryParams: {group, current_path: currentPath},
                    axiosParams: {
                        cancelToken: source.token,
                        cmsCall: true,
                        ...payload?.axiosParams
                    }
                });
            } catch (e) {
                if (!axios.isCancel(error)) {
                    logError(e.message || "failed to load CMS Data");
                }
                return;
            }
            commit("setCMSData", {data, group});
            return state.pageData;
        },
        async getFields({dispatch}, payload) {
            try {
                return await dispatch("core_cms/loadCMSData", {
                    queryParams: payload.queryParams,
                    onlySlug: true,
                    axiosParams: {
                        cache: {
                            ignoreCache: false
                        },
                        ...payload?.axiosParams
                    }
                }, {root: true});
            } catch (error) {
                logError(error);
            }
        },
        async getField({dispatch}, query) {
            try {
                const fields = await dispatch("getFields", {queryParams: query});
                return fields?.[query?.slug];
            } catch (error) {
                logError(error);
            }
        }
    }
};
