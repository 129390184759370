import {useScriptTag} from "@vueuse/core";
import {onBeforeUnmount, onBeforeMount, ref} from "vue";

interface UserWayEventDetail {
    userWayInstance: {
        iconVisibilityOff: () => void;
    };
}

declare global {
    interface Window {
        UserWay?: {
            widgetOpen: () => void;
        };
    }
}

export default function useUserway(account: string) {
    const isLoading = ref(false);
    const isReady = ref(false);

    const hideUserway = (event: CustomEvent<UserWayEventDetail>) => {
        const instance = event.detail.userWayInstance;
        instance.iconVisibilityOff();
    };

    const open = () => {
        isLoading.value = true;
        window.UserWay?.widgetOpen();
        setTimeout(() => isLoading.value = false, 3000); // I didn't find the way to check if userway is opened
    };

    onBeforeUnmount(() => {
        document.removeEventListener("userway:init_completed", hideUserway as EventListener);
    });

    document.addEventListener("userway:init_completed", hideUserway as EventListener);

    onBeforeMount(async () => {
        if (!account && import.meta.env.PROD) {
            console.error("Userway missing account id");
            return;
        }          

        try {
            const {load} = useScriptTag(
                "https://cdn.userway.org/widget.js",
                () => {},
                {attrs: {"data-account": account}, manual: true}
            );
            await load();
            isReady.value= true;
        } catch (e) {
            console.error("Userway failed to load");
        }
    });

    return {open, isLoading, isReady};
}
