import type {AxiosError} from "axios";
import type {SongFinchError} from "@songfinch/types";

export const isAxiosError = (error: unknown): error is AxiosError => {
    return !!(error as AxiosError)?.isAxiosError;
};

export const isSongfinchError = (error: unknown): error is SongFinchError => {
    return  !!error && typeof error === "object" && ("_sfErrorIgnore" in error || "redirectsList" in error);
};
