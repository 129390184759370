// const Home = () => import("@songfinch/customer/views/pages/Home.vue");
const Home2024 = () => import("@songfinch/customer/views/home/Home2024.vue");
const ArtistCommunity = () => import("@songfinch/customer/views/pages/ArtistCommunity.vue");
const SongRevisions = () => import("@songfinch/customer/views/pages/SongRevisions.vue");
const PrivacyPolicy = () => import("@songfinch/customer/views/pages/PrivacyPolicy.vue");
const Terms = () => import("@songfinch/customer/views/templates/Terms.vue");
const EventReminder = () => import("@songfinch/customer/views/pages/EventReminder.vue");
const Careers = () => import("@songfinch/customer/views/pages/Careers.vue");
const ContactUs = () => import("@songfinch/customer/views/pages/ContactUs.vue");
// const WorryFree = () => import("@songfinch/customer/views/pages/WorryFree.vue");
const HowItWorks = () => import("@songfinch/customer/views/pages/HowItWorks.vue");

export default [
    {
        path: "/",
        name: "Home",
        component: Home2024,
        meta: {
            CMSData: "homepage2024",
            removeClearfix: true,
            baseTheme: "dark",
        },
    },
    {
        path: "/artist-community",
        name: "ArtistCommunity",
        component: ArtistCommunity,
    },
    {
        path: "/song-revisions",
        name: "SongRevisions",
        component: SongRevisions,
    },
    {
        path: "/privacy",
        name: "PrivacyPolicy",
        component: PrivacyPolicy,
    },
    {
        path: "/terms",
        name: "Terms",
        component: Terms,
    },
    {
        path: "/event-reminder",
        name: "EventReminder",
        component: EventReminder,
    },
    {
        path: "/careers",
        name: "Careers",
        component: Careers,
    },
    {
        path: "/contact-us",
        name: "ContactUs",
        component: ContactUs,
    },
    {
        path: "/how-it-works",
        name: "HowItWorks",
        component: HowItWorks,
    },
];
