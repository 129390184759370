import axios from "axios";
import {indexOf, sortBy} from "lodash-es";
import router from "../router";
import useShowProductPaper from "../composables/product/useShowProductPaper";


export default {
    namespaced: true,
    state: {
        products: null,
        selectedStory: null
    },
    getters: {
        getAvailableProducts(state, val, rootState) {
            return state.products?.filter(p => {
                if (p.extra_info?.one_per_story) {
                    return !rootState.cart.cart.findProductByName(p.name);
                }
                return true;
            });
        },
    },
    mutations: {
        selectStory(state, val) {
            state.selectedStory = val;
        },
        setProducts(state, val) {
            state.products = val;
        },
        showProductDetailModal(state, payload) {
            const {showProductPaper} = useShowProductPaper();
            showProductPaper(payload);
        }
    },
    actions: {
        async loadProducts({commit}, {productsNames, sortByName, storyId, productsProps, includes, excludes} = {}) {
            commit("setProducts", null);
            let res;
            try {
                res = await axios.get("store/products", {
                    params: {
                        name: productsNames,
                        story_id: storyId,
                        includes,
                        excludes
                    }
                });
            } catch (e) {
                return router.push({name: "404"});
            }
            let result = res.data;
            if (sortByName?.length) {
                result = sortBy(res.data, (p) => {
                    const index = indexOf(sortByName, p.name);
                    return index === -1 ? sortByName.length : index;
                });
            }

            if (productsProps) {
                result = result.map(p => ({...p, ...productsProps}));
            }

            commit("setProducts", result);
            return result;
        },
    }
};
