import {ref} from "vue";

const showProductPaperActive = ref<boolean>(false);
const showProductPaperId = ref<string | undefined>(undefined);

export default function useShowProductPaper() {
    const showProductPaper = (id: string) => { 

        showProductPaperActive.value = true;
        showProductPaperId.value = id;
    };

    const removeProductPaper = () => { 
        showProductPaperActive.value = false;
        showProductPaperId.value = undefined;
    };


    return {
        showProductPaperActive,
        showProductPaperId,
        showProductPaper,
        removeProductPaper
    };
}
