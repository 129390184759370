import {defineStore} from "pinia";
import {createSongBuilder, fetchSongBuilder, fetchSongBuilderByEmail, updateSongBuilder} from "@songfinch/data-source/songbuilder";
import type {ArtistListType, ArtistType, SongBuilderState} from "@songfinch/types/types";
import {map, pick, size} from "lodash-es";
import {RouteParamsRaw} from "vue-router";
import {useSongBuilderProgress} from "@songfinch/customer/composables/useSongBuilderProgress";
import dayjs from "dayjs";
import modal from "@songfinch/shared/plugins/modal";
import router from "@songfinch/customer/router";
import {useStorage} from "@vueuse/core";
import storeGetUserIdentifier from "@songfinch/customer/store_v2/storeGetUserIdentifier";

const SONGBUILDER_STORAGE_SLUG = "sf_songbuilder_slug";
export const songBuilderSlug = useStorage(SONGBUILDER_STORAGE_SLUG, null);

export const FIELDS_LIMITS = Object.freeze({
    question: {maxlength: 10000},
    mustIncTitle: {maxlength: 100},
    mustIncDesc: {maxlength: 200},
});

export const useSongBuilderStore = defineStore("songBuilder", {
    state: (): SongBuilderState => ({
        recipient: undefined,
        mention_recipient: undefined,
        relationship: undefined,
        occasion: undefined,
        mention_occasion: undefined,
        preferred_artist_switch: undefined,
        preferred_artist: undefined,
        require_update_artist: undefined,
        gifter_email: undefined,
        selected_artists: undefined,
        genre: undefined,
        gender: undefined,
        tempo: undefined,
        moods: undefined,
        selected_recommended_artist: undefined,
        recommended_artists: undefined,
        lastSongBuildPage: undefined,
        song_product_name: undefined,
        pronunciations: undefined,
        questions: undefined,
        must_have_questions: undefined,
        deliveryType: undefined,
        selectedDeliveryMethod: undefined,
    }),

    getters: {
        getSongProduct: (state): string => state.song_product_name || "personalized-song",
        getArtistData: (state): ArtistType | ArtistListType | undefined => {
            if (state.selected_artists?.[0]) {
                return state.selected_artists[0];
            }
            return state.selected_recommended_artist;
        },
    },
    actions: {
        async migrateLocalStorageData() { // Can be removed later
            let data = localStorage.getItem("sf_song_data");
            data &&= JSON.parse(data);
            if (data?.lastSongBuildPage) {
                this.setData(data);
                await this.saveData();
                localStorage.removeItem("sf_song_data");
            }
        },
        async init() {
            await this.migrateLocalStorageData();

            if (songBuilderSlug.value) {
                try {
                    const remoteData = await fetchSongBuilder(songBuilderSlug.value);
                    this.setData(remoteData.song_data);
                } catch (e) {
                    songBuilderSlug.value = null;
                }
            }
        },
        setData(data: SongBuilderState) {
            this.$reset();
            this.$patch(data);
        },
        async saveData() {
            const data = {
                song_data: this.$state,
                email: this.gifter_email,
                external_user_id: storeGetUserIdentifier()
            };

            if (songBuilderSlug.value) {
                await updateSongBuilder(songBuilderSlug.value, data);
            } else {
                const resData = await createSongBuilder(data);
                songBuilderSlug.value = resData.slug;
            }
        },
        updateSelectedArtistsData() {
            this.selected_artists ||= [];
            const selected = this.selected_artists.filter((a) => a);
            if (!selected.length) {
                this.preferred_artist = "";
                return;
            }
            this.preferred_artist = selected.map((a) => a.artist_name).join(", ");

            const artist = selected[0];
            this.gender = "3";
            this.genre = artist.selected_genre; // Object.keys can be removed later
        },
        selectArtist(data: {artist: ArtistListType, index?: number }) {
            const artist = data.artist;
            if (!artist) return;
            this.selected_artists ||= [];

            this.preferred_artist_switch = true;

            if (!this.selected_artists?.find((a) => a?.id === artist.id)) {
                const artistData = pick(artist, [
                    "id",
                    "artist_name",
                    "photo",
                    "genres",
                    "gender",
                    "fromSinglePage",
                    "available_delivery_days",
                    "artist_power_user",
                    "artist_verified",
                    "exclusivity",
                    "videos",
                ]);
                artistData.genresOptions = map(artistData.genres, (g, i) => ({id: i, text: g}));
                artistData.videos = artistData.videos ? artistData.videos.find((video) => video?.video_type === "Artist Intro") || null : null;
                if (size(artistData.genres) === 1) artistData.selected_genre = artistData.genresOptions[0].id;
                this.selected_artists[0] = artistData;
                this.updateSelectedArtistsData();
            }
        },
        removeSelectedArtist(index: number) {
            this.selected_artists?.splice(index, 1);
            this.updateSelectedArtistsData();
        },
        clearSelectedArtists() {
            this.selected_artists = undefined;
            this.preferred_artist = "";
        },

        setRecommendedArtists(val?: ArtistType[]) {
            this.recommended_artists = val;
        },
        selectRecommendedArtist(val?: ArtistType) {
            this.selected_recommended_artist = val;
        },
        setLastSongBuildPage(route?: RouteParamsRaw) {
            this.lastSongBuildPage = route;
        },
        addPronunciation() {
            this.pronunciations.push({subject: "", pronounce: "", audio: null, saved: false});
        },
        removePronunciation(index) {
            this.pronunciations.splice(index, 1);
        },
        async validateSongData({redirect, toPage}?: {redirect?: boolean, toPage?: string} = {}) {
            redirect ??= true;
            const error = "";
            const query = {};
            let showWarning = true;
            let invalidPage = "";

            const {progressStats, sections} = useSongBuilderProgress();

            if (!progressStats.value.valid) {
                sections.find(item => {
                    if (item.isCompleted) return false;
                    invalidPage = item.id;
                    return true;
                });
            }

            if (!invalidPage && this.pronunciations?.length) {
                const startLength = this.pronunciations.length;
                this.pronunciations = this.pronunciations.filter(p => !(p.expire && dayjs().isAfter(p.expire)));
                if (startLength !== this.pronunciations.length) {
                    invalidPage ||= "BsReview";
                    showWarning = false;
                    redirect = toPage !== "BsReview";
                    modal.swal({
                        icon: "warning",
                        html: error || "Your pronunciation audio file has expired. Please add a new one."
                    });
                }
            }

            if (!invalidPage && this.require_update_artist) {
                invalidPage =  "BsSongArtist";
                showWarning = false;
            }

            if (this.selected_artists?.some(a => !a.selected_genre)) {
                invalidPage = "BsSongArtistSelection";
            }

            if (invalidPage) {
                if (showWarning) {
                    modal.swal({
                        icon: "warning",
                        html: error || "Please fill out all required fields before proceeding."
                    });
                }
                this.setLastSongBuildPage({name: invalidPage, query});
                if (redirect && router.currentRoute.value.name !== invalidPage) {
                    await router.push({name: invalidPage, query});
                }
                return false;
            }
            return true;
        },
        isArtistAvailableForDayRush(add_days) {
            if (+add_days > 3) return true;
            const artistData = this.getArtistData;
            if (!artistData || !(artistData?.artist_verified || artistData?.artist_power_user || artistData?.exclusivity)) return false;
            return artistData.available_delivery_days?.includes(String(add_days));
        },
        resetData() {
            this.$reset();
            songBuilderSlug.value = null;
            // TODO: Do we need to reset in DB?
        },
        isInProgress() {
            return !!songBuilderSlug.value;
        },
        async loadUserData(email) {
            if (!songBuilderSlug.value) {
                try {
                    const data = await fetchSongBuilderByEmail(email);
                    if (data) {
                        this.setData(data[0]?.song_data);
                        songBuilderSlug.value = data[0]?.slug;
                    }
                } catch (e) {}
            }
        }
    },
});
