export const scrollLock = () => {
    if (!document?.body && document?.documentElement) return;
    document.body.classList.add("scroll-lock");
    document.documentElement.classList.add("scroll-lock");
};

export const scrollUnlock = () => {
    if (!document?.body) return;
    document.body.classList.remove("scroll-lock");
    document.documentElement.classList.remove("scroll-lock");
};

export const smoothScrollTo = (targetPosition: number, duration = 1000) => {
    const startPosition = window.scrollY;
    const distance = targetPosition - startPosition;
    let startTime: number | null = null;

    const animation = (currentTime: number) => {
        if (startTime === null) startTime = currentTime;
        const timeElapsed = currentTime - startTime;
        const progress = Math.min(timeElapsed / duration, 1);

        const easeInOutQuad = progress < 0.5 
            ? 2 * progress * progress 
            : 1 - Math.pow(-2 * progress + 2, 2) / 2;

        window.scrollTo(0, startPosition + distance * easeInOutQuad);

        if (timeElapsed < duration) {
            requestAnimationFrame(animation);
        }
    };

    requestAnimationFrame(animation);
};
