const redirects = [
    {path: "/testimonials", redirect: "/"},
    {
        path: "/examples/inspiring-stories",
        redirect: "/playlists",
    },
    {
        path: "/songwriter-community",
        redirect: {name: "ArtistCommunity"},
        alias: "/songwriter",
    },
    {
        path: "/personalized-song-revisions",
        redirect: {name: "SongRevisions"},
    },
    {
        path: "/song-builder/song/:path?",
        redirect: (to) => `/song-builder/${to.params.path}`,
    },
    {
        path: "/song-builder/occasion/:path?",
        redirect: (to) => `/song-builder/${to.params.path}`,
    },
    {
        path: "/song-builder/story/:path?",
        redirect: "/song-builder",
    },
    {
        path: "/worry-free",
        redirect: "/song-revisions",
    },
    {
        path: "/worry-free-guarantee",
        redirect: "/song-revisions",
    },
    {
        path: "/instant",
        alias: "/jukebox",
        name: "InstantProductBundle",
        beforeEnter: (to) => {
            const url = new URL(window.appSettings.instantLandingURL);
            url.search = new URLSearchParams(to.query);
            location.href = url;
            return false;
        },
    },
    {
        path: "/instant-builder/intro",
        redirect: {name: "IpCollections"},
    },
    {
        path: "/song-builder/intro",
        name: "IpIntroPage",
        redirect: {name: "BsOccasionRecipient"},
    },
    {
        path: "/song-builder/genre",
        name: "BsSongGenre",
        redirect: {name: "BsSongCharacteristics"},
    },
    {
        path: "/song-builder/vocals",
        name: "BsSongVocals",
        redirect: {name: "BsSongCharacteristics"},
    },
    {
        path: "/song-builder/mood",
        name: "BsSongMood",
        redirect: {name: "BsSongCharacteristics"},
    },
    {
        path: "/song-builder/tempo",
        name: "BsSongTempo",
        redirect: {name: "BsSongCharacteristics"},
    },
    {
        path: "/song-builder/gifter",
        name: "BsOccasionGifter",
        redirect: {name: "BsOccasionRecipient"},
    },
    {
        path: "/song-builder/subject",
        name: "BsStorySubject",
        redirect: {name: "BsStoryDetailsCombined"},
    },
];

export default redirects;
