import {forEach, filter} from "lodash-es";
import {useSongBuilderStore} from "@songfinch/customer/store_v2/useSongBuilderStore";
const dbVals = window.appSettings.events_value;
const gtmSongData = {};

const gtm_song_data_helper = {
    BsOccasionRecipient: () => {
        const storeSongBuilder = useSongBuilderStore();
        if (!storeSongBuilder.recipient || !storeSongBuilder.relationship) return;
        gtmSongData.recipient = storeSongBuilder.recipient;
        gtmSongData.relationship = storeSongBuilder.relationship;
        gtmSongData.mention_recipient = storeSongBuilder.mention_recipient;

        gtmSongData.occasion = storeSongBuilder.occasion;
        gtmSongData.mention_occasion = storeSongBuilder.mention_occasion;
        gtmSongData.step_name = "BsOccasionRecipient";
        gtmSongData.step_number = 1;
    },
    BsGifterEmail2: () => {
        const storeSongBuilder = useSongBuilderStore();
        if (!storeSongBuilder.gifter_email ) return;
        gtmSongData.gifter_email = storeSongBuilder.gifter_email;
        gtmSongData.step_name = "BsGifterEmail2";
        gtmSongData.step_number = 2;
    },
    BsSongArtist: () => {
        const storeSongBuilder = useSongBuilderStore();
        if (storeSongBuilder.preferred_artist_switch === undefined) return;
        gtmSongData.preferred_artist_switch = storeSongBuilder.preferred_artist_switch;
        gtmSongData.step_name = "BsSongArtist";
        gtmSongData.step_number = 5;
    },
    BsSongCharacteristics: () => {
        const storeSongBuilder = useSongBuilderStore();
        if (!storeSongBuilder.gender || !storeSongBuilder.genre || !storeSongBuilder.moods?.length || !storeSongBuilder.tempo) return;
        gtmSongData.gender = dbVals.genders[storeSongBuilder.gender];
        gtmSongData.genre = dbVals.genres[storeSongBuilder.genre];
        gtmSongData.moods = storeSongBuilder?.moods.map(m => dbVals.moods[m]).join(", ") ;
        gtmSongData.tempo = dbVals.tempos[storeSongBuilder.tempo];
        gtmSongData.step_name = "BsSongCharacteristics";
        gtmSongData.step_number = 6;
    },
    BsSongArtistsList: () => {
        const storeSongBuilder = useSongBuilderStore();
        if (!storeSongBuilder.selected_recommended_artist) return;
        gtmSongData.recommended_artist_name = storeSongBuilder.selected_recommended_artist.artist_name;
        gtmSongData.recommended_artist_id = storeSongBuilder.selected_recommended_artist.id;
        gtmSongData.recommended_artists = storeSongBuilder.recommended_artists;

        gtmSongData.step_name = "BsSongArtistsList";
        // This step is skipped in the selected artist flow
        gtmSongData.step_number = 7;

        // TODO: Note this is for backwards compatibility, we are updating the field name for a misspelling and need to update GTM after this
        gtmSongData.submited_artists = null;
        gtmSongData.submitted_artists = null;
    },
    BsSongArtistSelection: () => {
        const storeSongBuilder = useSongBuilderStore();
        if (!storeSongBuilder.selected_artists?.length) return;
        // TODO: Note this is for backwards compatibility, we are updating the field name for a misspelling and need to update GTM after this
        gtmSongData.submited_artists = storeSongBuilder.selected_artists;
        gtmSongData.submitted_artists = gtmSongData.submited_artists;
        gtmSongData.step_name = "BsSongArtistSelection";
        gtmSongData.step_number = 5.1;

        gtmSongData.recommended_artist_name = null;
        gtmSongData.recommended_artist_id = null;
        gtmSongData.recommended_artists = null;
    },
    BsStoryDetailsCombined: () => {
        const storeSongBuilder = useSongBuilderStore();
        if (!storeSongBuilder.questions?.[0] || !storeSongBuilder.questions?.[1]) return;
        gtmSongData.story_details = `**${storeSongBuilder.questions[0].question}**\r\n${storeSongBuilder.questions[0].answer}`;
        gtmSongData.story_additional_details = `**${storeSongBuilder.questions[1].question}**\r\n\r\n${storeSongBuilder.questions[1].answer}`;

        if (storeSongBuilder.must_have_questions) {
            const new_array = {};
            forEach(storeSongBuilder.must_have_questions, (el, index) => {
                if (el.answer) {
                    new_array[`musthave_${index + 1}`] = `**${el.answer}** - ${el.description}`;
                }
            });
            gtmSongData.gtm_m_h_questions = JSON.stringify(new_array);
        }
        gtmSongData.step_name = "BsStoryDetails";
        gtmSongData.step_number = 10;
    },
    BsStoryPronunciation: () => {
        const storeSongBuilder = useSongBuilderStore();
        if (!storeSongBuilder.pronunciations?.length) return;
        const filtered_pronunciations = filter(storeSongBuilder.pronunciations, (p) => {
            return p.pronounce || p.subject;
        });
        gtmSongData.pronunciations = filtered_pronunciations;
        gtmSongData.step_name = "BsStoryPronunciation";
        gtmSongData.step_number = 14;
    },
    BsReview: () => {
        gtmSongData.step_name = "BsReview";
        gtmSongData.step_number = 12;
    }
};

const initGtmData = () => {
    Object.values(gtm_song_data_helper).forEach(v => v());
};

export default gtm_song_data_helper;

export {initGtmData, gtmSongData};
